import React from "react";

const Loading = () => (
  <div
    style={{
      backgroundColor: "white",
      zIndex: 9999,
      height: "100vh",
      width: "100vw",
      position: "fixed",
      top: "0",
      left: "0",
    }}
  />
);

export default Loading;
