import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import Loading from "../components/Loading";


const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading: Loading,
});
const Header = Loadable({
  loader: () => import("../components/about/Header"),
  loading: Loading,
});
const AboutUs = Loadable({
  loader: () => import("../components/about/AboutUs"),
  loading: Loading,
});
const OurCoreValues = Loadable({
  loader: () => import("../components/about/OurCoreValues"),
  loading: Loading,
});
const CoreTeam = Loadable({
  loader: () => import("../components/about/CoreTeam"),
  loading: Loading,
});
const Services = Loadable({
  loader: () => import("../components/index/Services"),
  loading: Loading,
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading: Loading,
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading: Loading,
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading: Loading,
});

export default () => {
  return (
    <Layout>
      <SEO
        title="About us | Datum Consulting NZ"
        keywords={[
          `about us`,
          `team members`,
          `core value`,
          `aws`,
          `azure`,
          `contact`,
          `get in touch`,
          `software development`,
          `digital transformation`,
          `software delivery`,
          `enterprise support`,
          `it consulting`,
        ]}
        pathname="about"
      />
      <Nav/>
      <Header/>
      <AboutUs/>
      <OurCoreValues/>
      <Services/>
      <CoreTeam/>
      <ContactForm/>
      <SubscribeFooter/>
      <Footer/>
    </Layout>
  );
};
